#webpack-dev-server-client-overlay {
    display: none;
}
/*--- resets start ---*/

#app {
    height: 100vh;
}

.content-body-wrapper {
    @extend .height-100vh;
    @extend .overflow-y-auto;
    @extend .bg-white;
}

html,
body,
p,
label,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
input,
strong,
button {
    font-family: 'Open Sans', sans-serif !important;
}

body {
    padding: 0 !important;
}

/*--- resets end ---*/

/*--- custom table start ---*/
.MuiTable-root {
    @extend .border-collapse;
}

.MuiTableRow-root {
    @extend .border-bottom-2;

    .MuiTablePagination-select {
        @extend .min-width-40;
        @extend .bg-content-wrapper;
        @extend .f-16;
        @extend .fw-400;
        @extend .border-bottom-2;
        @extend .text-align-start;
        @include custom-border-radius(4px, 4px, 0px, 0px);
        text-align-last: left;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

.custom-table-height {
    max-height: 66vh;
}
.current-time-indicator {
    min-height: calc(100vh - 717px);
}

.past-event {
    @extend .bg-gray-50;
    @extend .w-100;
    @extend .padding-x-8;
    @extend .border-radius-4;
    @extend .gray-300;
}

.ongoing-event {
    @extend .bg-primary;
    @extend .w-100;
    @extend .padding-x-8;
    @extend .border-radius-4;
    @extend .text-white;
}

.future-event {
    @extend .bg-primary-50;
    @extend .w-100;
    @extend .padding-x-8;
    @extend .border-radius-4;
    @extend .text-primary;
}

.past-event-component {
    @extend .bg-gray-50;
    @extend .gray-300;
}

.ongoing-event-component {
    @extend .bg-primary;
    @extend .text-white;
}

.future-event-component {
    @extend .bg-primary-50;
    @extend .text-primary;
}

.past-text-event {
    @extend .bg-transparent;
    @extend .gray-300;

    .dot {
        @extend .height-12;
        @extend .width-12;
        @extend .border-radius-50;
        @extend .bg-gray-300;
    }
}

.ongoing-text-event {
    @extend .bg-transparent;
    @extend .text-primary;

    .dot {
        @extend .height-12;
        @extend .width-12;
        @extend .border-radius-50;
        @extend .bg-primary;
    }
}

.future-text-event {
    @extend .bg-transparent;
    @extend .text-primary;

    .dot {
        @extend .height-12;
        @extend .width-12;
        @extend .border-radius-50;
        @extend .bg-primary-50;
    }
}

.MuiTableCell-root:before {
    z-index: 0 !important;
}

.MuiTableCell-root {
    border-bottom: 0px !important;
}
/*--- custom table end ---*/

/*-- wrapper class for tabs start --*/
.start-align {
    .MuiTabs-flexContainer {
        @extend .justify-content-start;
    }
    .emptyStateWrapper {
        height: calc(100vh - 200px);
    }
}
/*-- wrapper class for tabs end --*/

/*-- scroll bar start --*/
::-webkit-scrollbar {
    @extend .width-6;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    @extend .bg-scrollbar;
    @extend .border-radius-3;
}
/*-- scroll bar end --*/
.user-menu {
    .MuiAvatar-root {
        background: none;
    }
    .MuiSvgIcon-root {
        @extend .color-content;
    }
}

.table-search-bar {
    position: absolute;
    top: 40px;
}

.select {
    .MuiInputBase-root-MuiInput-root:before {
        border: none;
    }
}

/*--- classes to rotate the expand list icon start ---*/
.rotated {
    transition: 0.2s;
    @extend .rotate-180;
}

.not-rotated {
    transition: 0.2s;
    @extend .rotate-0;
}
/*--- classes to rotate the expand list icon end---*/

/*--- classes for grid-layout start ---*/
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    @extend .min-width-48;
    @extend .min-height-48;
    @extend .border-radius-bottom-right;
    @extend .padding-0;
    background-image: url('../assets/images/arrow-icon.png');
    background-repeat: no-repeat;
    background-size: cover;
    @extend .padding-left-20;
    @extend .bg-white;
    @extend .flex-center-align;
}

.view-dashboard .react-resizable-handle {
    display: none !important;
}

.react-grid-item > .react-resizable-handle::after {
    content: '';
    @extend .display-none;
}

.card-footer {
    @extend .bg-white;
    @extend .position-fixed;
    @extend .w-100;
    @extend .padding-y-4;
    @extend .border-top-1;
    bottom: 0;
}

.custom-height {
    max-height: 100%;
    height: 100%;
    @extend .overflow-y-auto;
}

.drag-drop-widget {
    max-height: 100%;
    @extend .padding-bottom-100;
}

/*--- classes for grid-layout end ---*/

.list-item-button {
    @extend .padding-0;

    &:hover {
        @extend .bg-transparent;
    }
}

/*--- class to remove padding-bottom from card-content ---*/
.MuiCardContent-root:last-child {
    @extend .padding-bottom-0;
}

.custom-select {
    .MuiSelect-select {
        @extend .padding-y-14;
    }
}

/*--- class for error button start ---*/
.error-btn {
    @extend .bg-error-btn;
    @extend .text-white;

    &:hover {
        @extend .bg-error-btn;
        @extend .text-white;
    }
}

.error-btn-disabled {
    background-color: $disabled-bg-color !important;
    color: $disable-color !important;
    @extend .border-none;
    &:hover {
        background-color: $disabled-bg-color !important;
        color: $disable-color !important;
    }
}
/*--- class for error button start ---*/

/*--- class to customize the label in primary color textfield (realmCustomization) ---*/
.custom-textfield {
    .MuiInputLabel-formControl {
        @extend .margin-left-36;
    }
}

/*--- classes for grouping of vertical tabs start ---*/
.role {
    @extend .position-relative;
    @extend .overflow-visible;
    @include margin(43px, 0px, 16px, 0px);
    &:before {
        content: 'ROLE';
        @extend .border-top-2;
        @extend .text-secondary;
        @extend .f-12;
        @extend .fw-600;
        @extend .width-280;
        @extend .justify-content-start;
        @extend .position-absolute;
        letter-spacing: 2px;
        text-transform: uppercase;
        bottom: 70px;
        margin-left: -16px;
        padding: 12px 16px 0px 16px;
    }
}

.privileges {
    @extend .role;
    margin-bottom: 0 !important;
    &:before {
        content: 'PRIVILEGES';
    }
}

.users {
    @extend .role;
    @extend .margin-top-55;
    &:before {
        content: 'USERS';
    }
}

.delete-role {
    @extend .role;
    margin: 24px 16px 8px 16px !important;
    &:before {
        bottom: 47px !important;
        content: '';
        margin: 0;
    }
}

/*--- classes for grouping of vertical tabs end ---*/
.modal-header {
    padding: 16px 24px 24px 24px;
    .modal-header-title {
        padding: 0px 0px 10px 0px;
    }
    .modal-description {
        padding-top: 16px;
    }
}

.custom-relocation-loader {
    position: relative;
    height: 120px;
    @extend .flex-center-align;
}

#group-type-selection {
    display: flex;
}

// linear gradient for score widget
.score-bg {
    background: linear-gradient(270deg, #e7f6e4 0%, rgba(231, 246, 228, 0) 100%);
}

.custom-add-device-field-lengths-style {
    @include fs-small;
    @include fw-normal;
    text-align: end;
    @include padding(5px, 16px, 0, 0);
    @include margin(0, 0, 16px, 0);
    color: $text-secondary;
}

.word-break {
    word-break: break-all;
}

.overflow-wrap {
    overflow-wrap: break-word;
}

.chip-avatar {
    .MuiChip-avatar {
        margin-left: 0 !important;
    }
}

/*--- drawer classes start ---*/
.custom-drawer {
    .MuiBackdrop-root-MuiModal-backdrop {
        background-color: transparent;
    }
}

.drawer {
    .BluiDrawerHeader-navigation {
        width: 80% !important;
    }
    .BluiDrawerHeader-nonClickableIcon {
        width: 100% !important;
    }
}

/*--- drawer classes start ---*/

.org-title {
    @extend .text-white;
    @extend .text-truncate;
    max-width: calc(50%) !important;
}

/*--- classes for default/satellite view start ---*/
.default-satellite {
    @extend .display-none;
    @extend .cursor-pointer;
    opacity: 0;
    visibility: hidden;

    &:hover {
        @extend .display-block;
        opacity: 1;
        visibility: visible;
    }
}

.layers:hover .default-satellite {
    @extend .display-block;
    opacity: 1;
    visibility: visible;
}

.default {
    @extend .height-52;
    @extend .width-52;
    background-image: url(https://maps.gstatic.com/tactile/layerswitcher/ic_default_colors2-1x.png);
    background-repeat: no-repeat;
    background-size: cover;

    border: 2px solid $primary;
    @extend .border-radius-8;
}

.satellite {
    @extend .default;
    background-image: url(https://maps.gstatic.com/tactile/layerswitcher/ic_satellite-1x.png);
}
/*--- classes for default/satellite view end ---*/

/*--- class for Schedule StepperModal start ---*/
.custom-tab-margin {
    margin-top: -34px;
}

.custom-toggle-btn {
    .MuiToggleButton-root {
        @extend .padding-x-22;
        @extend .padding-y-14;
    }
}

.custom-toggle-btn-grp {
    .MuiToggleButton-root {
        @extend .padding-x-31;
        @extend .padding-y-14;
    }
}
/*--- class for schedule StepperModal end ---*/

.entitlements-wrapper {
    height: calc(100vh - 216px);
    overflow-y: scroll;
    min-height: 550px;
    padding: 0px 8px 0px 2px;
}

.custom-accordion {
    margin-top: -6px;
}

.marker-label-with-avatar {
    position: absolute;
    left: 27px;
    top: 34px;
    -webkit-text-stroke: 1px #fff;
}

// device-trend scss
.delayed-input-class {
    input {
        text-align: end !important;
    }
}

.channel-value-textfield {
    .MuiFilledInput-input {
        padding-top: 6px !important;
    }
}

.build-version-text .BluiInfoListItem-subtitle {
    opacity: 0.5;
    text-align: end;
    font-weight: 600;
}

.default-cursor {
    .BluiInfoListItem-listItemButtonRoot {
        cursor: default !important;
    }
}

.cursor-pointer {
    .BluiInfoListItem-listItemButtonRoot {
        cursor: pointer !important;
    }
}

.MuiDateCalendar-root {
    width: 300px;
}

/*--- class for custom divider start ---*/
.custom-divider {
    @extend .width-2;
    @extend .bg-white;
    @extend .height-30;
    @extend .margin-left-8;
    @extend .margin-right-12;
}
/*--- class for custom divider end ---*/

/*--- class for drop-down-menu start ---*/
.drop-down-menu {
    position: fixed;
    top: 52px;
    @extend .padding-y-8;
    @extend .width-174;
}
/*--- class for drop-down-menu end ---*/
// rounded button
.contained-primary-rounded {
    border-radius: 50px;
    @extend .text-white;

    &:hover {
        @extend .text-white;
    }
}

.contained-danger-rounded {
    border-radius: 50px;
    background-color: $danger-color;
    @extend .text-white;

    &:hover {
        background-color: $danger-color;
        @extend .text-white;
    }
}

.contained-success-rounded {
    border-radius: 50px;
    background-color: $success-color;
    @extend .text-white;

    &:hover {
        background-color: $success-color;
        @extend .text-white;
    }
}

.custom-card-height {
    height: 100%;
    .MuiCardContent-root {
        height: 100%;
    }
    .MuiPaper-root {
        height: 100%;
        .MuiCardContent-root {
            height: 100%;
        }
    }
    .MuiCardContent-root:last-child {
        height: 100%;
    }
}

.custom-loader-height {
    height: calc(100vh - 224px) !important;
}

.stepper-content-wrapper {
    height: calc(100vh - 58px);
    @extend .bg-white;

    & .footer {
        position: fixed;
        bottom: 0;
        @extend .w-100;
        @extend .border-y-1;
    }

    & .channels-wrapper,
    .selected-channels-wrapper {
        height: calc(100vh - 384px);
        overflow-y: scroll;
        @extend .bg-content-wrapper;
    }

    & .download-sendByEmail {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        @extend .padding-y-24;
        @extend .padding-x-32;
        @extend .bg-content-wrapper;
    }
}

.trends-wrapper {
    height: calc(100vh - 135px);
    overflow: scroll;

    .trends-emptystate-wrapper {
        height: calc(100vh - 135px);
        overflow: hidden;
        @extend .bg-content-wrapper;
    }

    .trends-chart-container {
        .highcharts-container {
            height: calc(100vh - 200px);
        }
    }
}

.data-grid-wrapper {
    height: calc(100vh - 270px) !important;
    // height: 100%;
    // min-height: 400px;
}

.grid-header {
    background-color: #fff !important;
}

.indicator-class {
    ::before {
        content: ' ';
        position: absolute;
        top: -12px;
        bottom: -12px;
        left: -29px;
        width: 6px;
        z-index: 1;
    }
}

.indicator-bg-offline {
    ::before {
        background-color: $sky-800;
    }
}

.indicator-bg-alarm {
    ::before {
        background-color: $danger-color;
    }
}

.indicator-bg-warning {
    ::before {
        background-color: $gold-500;
    }
}

.indicator-bg-snooze {
    ::before {
        background-color: $purple-500;
    }
}
.hasScrollToRight {
    .MuiDataGrid-columnHeader--pinnedRight:nth-of-type(5n + 1) {
        box-shadow: -8px 0px 5px 1px rgba(0, 0, 0, 0.05);
    }

    .MuiDataGrid-cell--pinnedRight:nth-of-type(5n + 1) {
        box-shadow: -8px 6px 5px 1px rgba(0, 0, 0, 0.05);
    }
}

.hasScrollToLeft {
    .MuiDataGrid-columnHeader--pinnedLeft:nth-last-child(n) {
        box-shadow: 7px -1px 5px 1px rgba(0, 0, 0, 0.05);
    }
    .MuiDataGrid-cell--pinnedLeft:nth-last-of-type(5n + 1) {
        box-shadow: 7px 6px 5px 1px rgba(0, 0, 0, 0.05);
    }
}

.stepper-modal-height {
    height: calc(100vh - 150px);
}

.reactJsonWrapper {
    .react-json-view {
        border-radius: '5px';
        min-height: '300px';
        padding: '2px';
    }

    .variable-value {
        width: 350px;
        overflow-wrap: anywhere;
    }
}

// documents filter
.documents-filter {
    @extend .width-450;
    height: calc(100vh - 60px);
    overflow-y: auto;
    position: fixed;
    margin-top: -12px;
    right: 0;
    z-index: 5;
}

.MuiDataGrid-container--top {
    z-index: 5;
}
