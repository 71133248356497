/*----- Display flex classes For all Browsers e.g @extend display-flex -----*/
.display-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.d-flex {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
}
.d-flex-reverse {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
}

.d-flex-row {
    @extend .display-flex;
    @include margin(0px, -12px, 0px, -12px);
}

.d-flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.d-flex-column {
    @extend .display-flex;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

.flex-center-column {
    @extend .display-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flex-center-start {
    @extend .display-flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-center-between {
    @extend .display-flex;
    align-items: center;
    justify-content: space-between;
}

.flex-center-around {
    @extend .display-flex;
    align-items: center;
    justify-content: space-around;
}

.flex-center-align {
    @extend .display-flex;
    align-items: center;
    justify-content: center;
}

.flex-right-align {
    @extend .display-flex;
    @extend .align-items-center;
    @extend .justify-content-end;
}

.align-items-center {
    @extend .display-flex;
    align-items: center;
}

.align-items-end {
    @extend .display-flex;
    align-items: end;
}

.align-items-start {
    @extend .display-flex;
    align-items: start;
}

.align-items-center-right {
    @extend .display-flex;
    @extend .align-items-center;
    @extend .justify-content-end;
}

.flex-row {
    @extend .display-flex;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.flex-row-reverse {
    @extend .display-flex;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}

.flex-column {
    @extend .display-flex;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.flex-column-center {
    @extend .flex-center-align;
    flex-direction: column;
}

.flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
}

.align-self-center {
    align-self: center;
}

.justify-content-start {
    @extend .display-flex;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justify-content-end {
    @extend .display-flex;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-center {
    @extend .display-flex;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justify-content-between {
    @extend .display-flex;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    @extend .display-flex;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}

/*------ End-------*/

//flex-basis
.flex-5 {
    flex-basis: 5% !important;
    max-width: 5% !important;
}
.flex-7_5 {
    flex-basis: 7.5% !important;
    max-width: 7.5% !important;
}
.flex-8 {
    flex-basis: 8% !important;
    max-width: 8% !important;
}
.flex-8_5 {
    flex-basis: 8.5% !important;
    max-width: 8.5% !important;
}
.flex-10 {
    flex-basis: 10% !important;
    max-width: 10% !important;
}
.flex-12 {
    flex-basis: 12% !important;
    max-width: 12% !important;
}
.flex-12_5 {
    flex-basis: 12.5% !important;
    max-width: 12.5% !important;
}
.flex-13 {
    flex-basis: 13% !important;
    max-width: 13% !important;
}
.flex-15 {
    flex-basis: 15% !important;
    max-width: 15% !important;
}
.flex-16 {
    flex-basis: 16% !important;
    max-width: 16% !important;
}
.flex-16_6 {
    flex-basis: 16.65% !important;
    max-width: 16.65% !important;
}
.flex-17 {
    flex-basis: 17% !important;
    max-width: 17% !important;
}
.flex-17_5 {
    flex-basis: 17.5% !important;
    max-width: 17.5% !important;
}
.flex-18_3 {
    flex-basis: 18.33% !important;
    max-width: 18.33% !important;
}
.flex-20 {
    flex-basis: 20% !important;
    max-width: 20% !important;
}
.flex-21_5 {
    flex-basis: 21.5% !important;
    max-width: 21.5% !important;
}
.flex-22_5 {
    flex-basis: 22.5% !important;
    max-width: 22.5% !important;
}
.flex-23_5 {
    flex-basis: 23.5% !important;
    max-width: 23.5% !important;
}
.flex-24 {
    flex-basis: 24% !important;
    max-width: 24% !important;
}
.flex-25 {
    flex-basis: 25% !important;
    max-width: 25% !important;
}
.flex-26 {
    flex-basis: 26% !important;
    max-width: 26% !important;
}
.flex-27_5 {
    flex-basis: 27.5% !important;
    max-width: 27.5% !important;
}
.flex-30 {
    flex-basis: 30% !important;
    max-width: 30% !important;
}
.flex-33 {
    flex-basis: 33.33% !important;
    max-width: 33.33% !important;
}
.flex-35 {
    flex-basis: 35% !important;
    max-width: 35% !important;
}
.flex-37_5 {
    flex-basis: 37.5% !important;
    max-width: 37.5% !important;
}
.flex-40 {
    flex-basis: 40% !important;
    max-width: 40% !important;
}
.flex-42_5 {
    flex-basis: 42.5% !important;
    max-width: 42.5% !important;
}
.flex-45 {
    flex-basis: 45% !important;
    max-width: 45% !important;
}
.flex-50 {
    flex-basis: 50% !important;
    max-width: 50% !important;
}
.flex-55 {
    flex-basis: 55% !important;
    max-width: 55% !important;
}
.flex-60 {
    flex-basis: 60% !important;
    max-width: 60% !important;
}
.flex-65 {
    flex-basis: 65% !important;
    max-width: 65% !important;
}
.flex-66 {
    flex-basis: 66.67% !important;
    max-width: 66.67% !important;
}
.flex-70 {
    flex-basis: 70% !important;
    max-width: 70% !important;
}
.flex-75 {
    flex-basis: 75% !important;
    max-width: 75% !important;
}
.flex-80 {
    flex-basis: 80% !important;
    max-width: 80% !important;
}
.flex-82_5 {
    flex-basis: 82.5% !important;
    max-width: 82.5% !important;
}
.flex-84 {
    flex-basis: 84% !important;
    max-width: 84% !important;
}
.flex-85 {
    flex-basis: 85% !important;
    max-width: 85% !important;
}
.flex-87_5 {
    flex-basis: 87.5% !important;
    max-width: 87.5% !important;
}
.flex-90 {
    flex-basis: 90% !important;
    max-width: 90% !important;
}
.flex-95 {
    flex-basis: 95% !important;
    max-width: 95% !important;
}
.flex-100 {
    flex-basis: 100% !important;
    max-width: 100% !important;
}
// end flex basis
