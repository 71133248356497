.BluiWorkflowCard-root {
    background-color: transparent !important;
    background-image: none !important;
}

.primary-auth {
    padding-bottom: 132px !important;

    &::after {
        content: '';
        width: 118px;
        height: 100px;
        background: url('../assets/images/cybersecurity-certified.svg');
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
    }

    a[aria-controls='help-links-container'] {
        display: none;
    }

    .primary-auth-form {
        position: relative;

        .o-form-content {
            padding-bottom: 76px !important;

            .o-form-error-container {
                position: relative;

                .okta-form-infobox-error {
                    position: absolute !important;
                    top: -48px;
                    z-index: 10;
                    right: 0px;
                    left: 0;
                }
            }

            .o-form-fieldset-container {
                .o-form-fieldset {
                    color: #2b353a !important;
                    .custom-checkbox {
                        width: 160px;
                    }
                }

                .o-form-input {
                    position: relative;

                    .o-form-input-name-remember {
                        .custom-checkbox {
                            label {
                                color: #424e54;
                                font-weight: 400 !important;
                                font-size: 16px !important;
                                padding-left: 32px !important;
                            }
                        }
                    }

                    .okta-form-input-error {
                        position: absolute !important;
                        bottom: -32px;
                    }
                }
            }
        }

        .o-form-button-bar {
            position: absolute;
            bottom: 48px;
            right: 0px;

            .button {
                height: 35px !important;
                background-color: #007bc1 !important;
                color: #ffffff !important;
                border: none !important;
                border-radius: 4px !important;
            }
        }
    }

    .auth-footer {
        position: relative;

        a[aria-expanded='true'] {
            display: none;
        }

        a[aria-expanded='false'] {
            display: none !important;
        }

        .help-links {
            display: block;
        }
    }

    .primary-auth-container {
        position: relative !important;
        .auth-divider {
            display: none;

            .auth-divider-text {
                display: none;
            }
        }
    }
}
#okta-sign-in.auth-container.main-container .okta-sign-in-header {
    height: 130px;
    padding-bottom: 0px;
}
#okta-sign-in .default-custom-button.link-button {
    background-color: transparent;
    color: #007bc1 !important;
    border: none;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    margin: 40px auto 44px auto !important;
    min-width: 100px;
    width: 100px;
    line-height: 0px;
    height: 0px;

    &:last-of-type {
        margin-bottom: 0px !important;
    }
    &:first-of-type {
        margin-top: 0px !important;
    }
}

.btn-customAuth::before {
    content: "Don't have an account?";
    position: absolute;
    top: -20px;
    color: #9b9b9b !important;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    right: auto;
    width: 100%;
    pointer-events: none;
}

#okta-sign-in.auth-container.main-container .margin-btm-5 {
    margin-bottom: 30px;

    .okta-form-label {
        label {
            font-size: 16px !important;
            color: #2b353a !important;
        }
    }
}

#okta-sign-in.auth-container.main-container .margin-btm-30 {
    margin-bottom: 46px;

    .okta-form-label {
        label {
            font-size: 16px !important;
            color: #2b353a !important;
        }
    }
}

#okta-sign-in #okta-sign-in .auth-org-logo {
    max-height: 20px;
}

#okta-sign-in {
    margin: auto;

    .auth-content {
        padding: 0px 30px 30px 30px !important;
    }

    .o-form {
        .input-fix {
            background-color: #f7f8f8;
            border-bottom: 2px solid rgba(66, 78, 84, 0.12) !important;
            border: 0px;
            border-radius: 4px 4px 0px 0px;
            input:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 1000px #f7f8f8 inset;
                border-radius: 4px 4px 0px 0px;
            }
        }
    }
}

#okta-sign-in.auth-container .okta-form-input-field.focused-input {
    border-color: #007bc1 !important;
}

#okta-sign-in .focused-input {
    box-shadow: none;
}

#okta-sign-in input[type='text']:focus {
    box-shadow: none;
}

#help-links-container {
    display: block !important;
    // li{
    //   margin-top: 5px !important;
    // }
}

#okta-sign-in .auth-content .help-links li:first-child {
    position: absolute;
    top: -180px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;

    a {
        color: #007bc1;
        font-weight: 600;
        text-decoration: none;
    }
}
#okta-sign-in .auth-content .help-links li:last-child {
    display: none;
}

#okta-sign-in .auth-content .help-links li:last-of-type {
    text-align: center;
    margin-bottom: 24px;

    a {
        color: #007bc1;
        font-weight: 600;
        text-decoration: none;
    }
}

// logIn page end

// forgot Password start
.forgot-password {
    .forgot-password-email-enabled {
        .o-form-content {
            margin-bottom: 161px !important;
            .o-form-error-container {
                position: absolute !important;
                left: 0px !important;
                right: 0px !important;
                top: -36px !important;
                z-index: 11;
            }
            .okta-form-title {
                text-align: left !important;
                font-weight: 600 !important;
                font-size: 20px !important;

                &::before {
                    content: 'Please enter the account email associated with the account.';
                    position: absolute;
                    color: #424e54;
                    font-size: 16px;
                    font-weight: 400;
                    left: 0px;
                    right: 0px;
                    top: 45px;
                    line-height: 24px;
                }
            }

            .o-form-fieldset-container {
                padding-top: 200px !important;
                .o-form-fieldset {
                    .o-form-input {
                        position: relative;
                        .okta-form-input-error {
                            position: absolute !important;
                            bottom: -32px;
                        }
                    }
                }

                &::before {
                    content: 'If  this email has an account with Eaton, you will receive a response within ten minutes.';
                    position: absolute;
                    color: #424e54;
                    font-size: 16px;
                    font-weight: 400;
                    left: 0px;
                    right: 0px;
                    top: 106px;
                    line-height: 24px;
                }

                &::after {
                    content: ' For immediate support during business hours, 1-877-ETN-CARE, Option 2, Option 9.';
                    position: absolute;
                    color: #424e54;
                    font-size: 16px;
                    font-weight: 400;
                    left: 0px;
                    right: 0px;
                    top: 170px;
                    line-height: 22px;
                }

                .o-form-label-top {
                    .okta-form-label {
                        label {
                            font-size: 16px !important;
                            color: #2b353a;
                        }
                    }
                }

                .link-button {
                    position: absolute;
                    height: 36px !important;
                    width: auto !important;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    border: none !important;
                    right: 0px;
                    bottom: 6px;
                }
            }
        }
    }

    .auth-footer {
        padding: 16px 0px !important;

        .link {
            padding: 9px 16px !important;
            border: 1px solid #d5d8da !important;
            border-radius: 4px;
            color: #007bc1 !important;
            text-decoration: none;
            font-weight: 600 !important;
            font-size: 14px !important;

            &:focus {
                box-shadow: none !important;
            }
        }
    }
}
// forgot Password ends

// Email start
.password-reset-email-sent {
    .o-form {
        .o-form-content {
            padding-bottom: 0 !important;

            .okta-form-title {
                text-align: left !important;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                font-weight: 600 !important;
                font-size: 20px !important;
                padding-bottom: 150px !important;

                &::after {
                    content: '';
                    width: 96px;
                    height: 96px;
                    background: url('../assets/images/email.svg');
                    top: 50px;
                    left: 50%;
                    transform: translateX(-50%);
                    position: absolute;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }

            .okta-form-subtitle {
                font-size: 16px !important;
                font-weight: 400 !important;
                color: #424e54 !important;
                margin-bottom: 307px !important;
            }

            .o-form-fieldset-container {
                a {
                    border: none !important;
                    height: 36px !important;
                    border-radius: 3px !important;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                }
            }
        }
    }
}

// Email ends

// okta common start
.content-body {
    background-size: contain;
    background-position: center center;
    height: 100vh;
    .css-vjhf88 {
        height: 100%;
        overflow-y: auto;
        background-color: #f7f8f8;
    }
    .css-1dwxet {
        background-color: #f7f8f8;
    }
}

.Wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    #okta-sign-in .auth-org-logo {
        height: 60px !important;
        max-height: 60px !important;
    }
}

#okta-sign-in .auth-header {
    border: none;
    display: flex;
    padding: 55px 55px 75px;
}

#okta-sign-in.auth-container.main-container {
    max-height: 760px;
    height: 100%;
    min-width: 450px;
}

// okta common end

.separation-line::before,
.separation-line span,
.link.js-help,
.link.js-unlock,
.beacon-container {
    display: none;
}

#okta-sign-in.auth-container .button-primary {
    background: linear-gradient(#007bc1, #007bc1);
    border: none;
    box-shadow: none;
}

.super-realm-widget {
    .o-form-button-bar {
        .button-primary {
            margin-bottom: 56px;
        }
    }
}

.login-widget {
    .o-form-button-bar {
        .button-primary {
            margin-bottom: 72px;
        }
    }
}

.default-custom-button.link-button {
    margin: 10px 0px;
}

.login-wrapper {
    position: relative;
}

.locale-button-wrapper {
    position: absolute;
    top: 55px;
    right: 20px;
    border: none;
    z-index: 9999;
    color: #007bc1;
}
