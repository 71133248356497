/*--- classes for color start ---*/
.text-white {
    color: $white;
}

.text-black {
    color: $black;
}

.text-primary {
    color: $primary !important;
}

.color-warning {
    color: $warning-color;
}

.text-secondary {
    color: $text-secondary;
}

.text-success {
    color: $success-color;
}

.color-content {
    color: $content-color !important;
}

.content-wrapper {
    color: $content-wrapper;
}

.color-icon {
    color: $icon-color;
}

.color-icon-light {
    color: $icon-light-color;
}

.color-danger {
    color: $danger-color;
}

.red-400 {
    color: $red-400;
}

.black-300 {
    color: $black-300;
}

.black-400 {
    color: $black-400;
}

.black-500 {
    color: $black-500;
}

.black-700 {
    color: $black-700;
}

.black-900 {
    color: $black-900;
}

.sky-100 {
    color: $sky-100;
}

.sky-800 {
    color: $sky-800;
}

.gold-500 {
    color: $gold-500;
}

.gold-600 {
    color: $gold-600;
}

.gold-700 {
    color: $gold-700;
}

.gold-800 {
    color: $gold-800;
}

.gold-900 {
    color: $gold-900;
}

.sunset-800 {
    color: $sunset-800;
}

.purple-500 {
    color: $purple-500;
}

.orange-500 {
    color: $orange-500;
}

.green-500 {
    color: $green-500;
}

.gray-200 {
    color: $gray-200;
}

.gray-300 {
    color: $gray-300;
}

.gray-50 {
    color: $gray-50;
}

.gray-700 {
    color: $gray-700;
}

.color-disable {
    color: $disable-color;
}

.disabled-link {
    color: $disabled-link;
}

/*--- classes for color end ---*/

/*--- classes for background colors start ---*/
.bg-none {
    background: none;
}

.bg-white {
    background-color: $white;
}

.bg-black {
    background-color: $black;
}
.bg-black-500 {
    background-color: $black-500;
}

.bg-header {
    background-color: $header-bg;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-content-wrapper {
    background-color: $content-wrapper;
}

.bg-content {
    background-color: $content-color;
}

.bg-primary-50 {
    background-color: $primary-50;
}

.bg-primary {
    background-color: $primary;
}

.bg-danger {
    background-color: $danger-color;
}

.bg-red-50 {
    background-color: $red-50;
}

.bg-red-400 {
    background-color: $red-400;
}

.bg-red-600 {
    background-color: $red-600;
}

.bg-gray-50 {
    background-color: $gray-50;
}

.bg-gray-200 {
    background-color: $gray-200;
}
.bg-gray-300 {
    background-color: $gray-300;
}

.bg-blue-400 {
    background-color: $blue-400;
}

.bg-blue-600 {
    background-color: $blue-600;
}

.bg-orange-50 {
    background-color: $orange-50;
}

.bg-orange-700 {
    background-color: $orange-700;
}

.bg-green-50 {
    background-color: $green-50;
}

.bg-green-500 {
    background-color: $green-500;
}

.bg-green-700 {
    background-color: $green-700;
}

.bg-light-green {
    background-color: $light-green;
}

.bg-sky-50 {
    background-color: $sky-50;
}

.bg-sky-800 {
    background-color: $sky-800;
}

.bg-purple-500 {
    background-color: $purple-500;
}

.bg-sunset-500 {
    background-color: $sunset-500;
}

.bg-sunset-800 {
    background-color: $sunset-800;
}

.bg-gold-50 {
    background-color: $gold-50;
}

.bg-gold-500 {
    background-color: $gold-500;
}

.bg-gold-800 {
    background-color: $gold-800;
}

.bg-goldenRod-500 {
    background-color: $goldenrod-500;
}

.bg-yellow-500 {
    background-color: $yellow-500;
}

.bg-list-item-hover {
    background-color: $list-item-hover-bg;
}

.bg-list-item-active {
    background-color: $list-active-item;
}

.bg-error-btn {
    background-color: $error-btn;
}

.bg-grade {
    background-color: $grade;
}

.bg-light-blue-avatar {
    background-color: $light-blue-avatar !important;
}

.bg-scrollbar {
    background-color: $scrollbar;
}
.bg-light-blue {
    background-color: $light-blue !important;
}

/*--- classes for background colors end ---*/
