.hierarchy-view {
    @extend .w-100;
    background-color: inherit;

    &.hierarchy-item-root {
        @extend .align-items-center;
        @extend .max-height-54;
        @extend .cursor-pointer;
        @extend .padding-8;

        svg {
            font-size: 24px;
        }

        & .MuiTypography-h5 {
            // @extend .text-secondary;
            @extend .fw-600;
            @extend .f-14;
            @extend .padding-left-0;
            @include text-truncate;
            // @extend .max-width-200;
        }

        & .MuiTypography-h6 {
            // @extend .text-secondary;
            @extend .fw-400;
            @extend .f-14;
            @include text-truncate;
            @extend .max-width-150;
        }
    }

    // &.hierarchy-item-selected {
    //     background-color: rgba(0, 94, 171, 0.08);
    // }

    & .hierarchy-item-label {
        min-width: 0;
        position: relative;
        line-height: 1.5;
    }

    & .hierarchy-item-iconContainer {
        margin-right: 4px;
        flex-shrink: 0;
        justify-content: center;
    }

    &.hierarchy-item-disabled {
        opacity: 0.38;
        background-color: transparent;
    }

    .MuiCollapse-root.MuiCollapse-vertical.MuiTreeItem-group.MuiCollapse-entered {
        @extend .margin-left-0;
    }
}

.dnd-hierarchy {
    .hierarchy-item-root:hover {
        background-color: transparent;
    }
    .hierarchy-item-root.Mui-focused {
        background-color: transparent;
    }

    & .hierarchy-item-root {
        padding-left: 0px;
    }

    &.hierarchy-drop-target {
        outline: 3px solid #007bc1;
        background-color: #000 !important;
    }
}

.MuiStack-root .hierarchy-item-iconContainer.MuiBox-root {
    padding: 8px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    .dnd-hierarchy &:has(svg):hover {
        background-color: #007bc10a;
        color: #007bc1;
    }
}
